module.exports = {
	KEY_CODES: {
		UP: 38,
		DOWN: 40,
		LEFT: 37,
		RIGHT: 39,
		SPACE: 32,
		ENTER: 13,
		DELETE: 46,
		ESC: 27,
		TAB: 9
	}
};