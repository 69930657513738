var utils = require("../../utils/utils");
var createArgumentsHelper = require("./calendar_arguments_helper");
var CalendarMergeHelper = require("./strategy/work_calendar_merger");
var CalendarWorkTimeStrategy = require("./strategy/calendar_strategy");
var legacyResourceCalendarConfig = require("./legacy_resource_config");
var dynamicResourceCalendars = require("./dynamic_resource_calendars")();

function CalendarManager (gantt){
	this.$gantt = gantt;
	this._calendars = {};
}

CalendarManager.prototype = {
	_calendars: {},
	_convertWorkTimeSettings: function (settings) {
		var days = settings.days;
		if (days && !settings.dates) {
			settings.dates = settings.dates || {};
			for (var i = 0; i < days.length; i++) {
				settings.dates[i] = days[i];
				if (!(days[i] instanceof Array)) {
					settings.dates[i] = !!days[i];
				}
			}
		}
		delete settings.days;
		return settings;
	},
	mergeCalendars: function(){
		var calendars = [];
		var args = arguments;
		if(Array.isArray(args[0])){
			calendars = args[0].slice();
		}else{
			for(var i = 0; i < arguments.length; i++){
				calendars.push(arguments[i]);
			}
		}

		var mergeHelper = new CalendarMergeHelper();

		var result;
		calendars.forEach(function(calendar){
			if(!result){
				result = calendar;
			} else{
				result = this._createCalendarFromConfig(mergeHelper.merge(result, calendar));
			}
			
		}.bind(this));
		return this.createCalendar(result);
	},

	_createCalendarFromConfig: function(config){
		var apiCore = new CalendarWorkTimeStrategy(this.$gantt, createArgumentsHelper(this.$gantt));
		apiCore.id = String(utils.uid());
		apiCore._setConfig(this._convertWorkTimeSettings(config));

		return apiCore;
	},

	createCalendar: function (parentCalendar) {
		var settings;

		if (!parentCalendar) {
			parentCalendar = {};
		}

		if (parentCalendar.getConfig){
			settings = utils.copy(parentCalendar.getConfig());
		} else if (parentCalendar.worktime) {
			settings = utils.copy(parentCalendar.worktime);
		} else {
			settings = utils.copy(parentCalendar);
		}

		var defaults = utils.copy(this.defaults.fulltime.worktime);
		utils.mixin(settings, defaults);

		return this._createCalendarFromConfig(settings);
	},

	getCalendar: function (id) {
		id = id || "global";
		this.createDefaultCalendars();
		return this._calendars[id];
	},

	getCalendars: function () {
		var res = [];
		for (var i in this._calendars) {
			res.push(this.getCalendar(i));
		}
		return res;
	},

	_getOwnCalendar: function(task){
		var config = this.$gantt.config;
		if (task[config.calendar_property]) {
			return this.getCalendar(task[config.calendar_property]);
		}

		if (config.resource_calendars) {
			var calendar;
			var calendarId;
			var resourceProperty = legacyResourceCalendarConfig.getResourceProperty(config);
			if(Array.isArray(task[resourceProperty])){
				// if multiple resources are attached to the task - merge their calendars
				if(config.dynamic_resource_calendars){
					calendarId = dynamicResourceCalendars.getCalendarIdFromMultipleResources(task[resourceProperty], this);
				}
			}else{
				if(legacyResourceCalendarConfig.isLegacyResourceCalendarFormat(config.resource_calendars)){
					var calendarId = legacyResourceCalendarConfig.getCalendarIdFromLegacyConfig(task, config.resource_calendars);
				}else if(resourceProperty && task[resourceProperty] && config.resource_calendars[task[resourceProperty]]){
					var calendar = this.getResourceCalendar(task[resourceProperty]);
				}
			} 

			if(calendarId){
				calendar = this.getCalendar(calendarId);
			}

			if(calendar){
				return calendar;
			}
		}
		return null;
	},

	/**
	 * Returns calendar assigned to the specified resource.
	 * Returns the global calendar if no custom calendar is associated with the resource.
	 * @param {(string|number|Object)} resource - resource object or resource id 
	 * @returns {object} Calendar object
	 */
	getResourceCalendar: function(resource) {
		if (resource === null || resource === undefined) {
			return this.getCalendar();
		}

		var resourceId = null;
		// if task id is provided
		if((typeof resource === "number" || typeof resource === "string")){
			resourceId = resource;
		}else{
			resourceId = resource.id || resource.key;
		}

		var config = this.$gantt.config;
		var calendarsConfig = config.resource_calendars;
		var calendarId = null;
		if (calendarsConfig) {
			if(legacyResourceCalendarConfig.isLegacyResourceCalendarFormat(calendarsConfig)){
				for(var field in calendarsConfig){
					if(calendarsConfig[field][resourceId]){
						calendarId = calendarsConfig[field][resourceId];
						break;
					}
				}
			}else{
				var calendarId = calendarsConfig[resourceId];
			}

			if(calendarId){
				return this.getCalendar(calendarId);
			}
			
		}
		return this.getCalendar();
	},

	/**
	 * Returns the calendar assigned to a task.
	 * - Returns a calendar assigned via task[gantt.config.calendar_property] if specified.
	 * - Returns a calendar assigned to the task resource if specified.
	 * - Returns the global calendar otherwise.
	 * @param {(string|number|Object)} task - task object or task id 
	 * @returns {object} Calendar object
	 */
	getTaskCalendar: function (task) {
		var gantt = this.$gantt;
		var taskObject;
		if (task === null || task === undefined) {
			return this.getCalendar();
		}

		// if task id is provided
		if((typeof task === "number" || typeof task === "string") && gantt.isTaskExists(task)){
			taskObject = gantt.getTask(task);
		}else{
			taskObject = task;
		}

		if(!taskObject){
			return this.getCalendar();
		}

		var calendar = this._getOwnCalendar(taskObject);
		if (!calendar && gantt.config.inherit_calendar && gantt.isTaskExists(taskObject.parent)){
			var stop = false;
			gantt.eachParent(function(parent){
				if(stop) return;
				if(gantt.isSummaryTask(parent)){
					calendar = this._getOwnCalendar(parent);
					if(calendar){
						stop = true;
					}
				}
			}, taskObject.id, this);
		}

		return calendar || this.getCalendar();
	},

	addCalendar: function(calendar) { // puts new calendar to Global Storage - gantt.calendarManager._calendars {}
		if (!(this.isCalendar(calendar))) {
			var id = calendar.id;
			calendar = this.createCalendar(calendar);
			calendar.id = id;
		}

		// validate/check if empty calendar
		if (!calendar._tryChangeCalendarSettings(function () {
			})) {

			this.$gantt.callEvent("onCalendarError", [{message: "Invalid calendar settings, no worktime available"}, calendar]);
			return null;
		} else {
			var config = this.$gantt.config;

			calendar.id = calendar.id || utils.uid();
			this._calendars[calendar.id] = calendar;
			if (!config.worktimes)
				config.worktimes = {};
			config.worktimes[calendar.id] = calendar.getConfig();
			return calendar.id;
		}
	},

	deleteCalendar: function (calendar) {
		var config = this.$gantt.config;
		if (!calendar) return false;
		if (this._calendars[calendar]) {
			delete this._calendars[calendar];
			if (config.worktimes && config.worktimes[calendar])
				delete config.worktimes[calendar];
			return true;
		} else {
			return false;
		}
	},

	restoreConfigCalendars: function (configs) {
		for (var i in configs) {
			if (this._calendars[i])
				continue;

			var settings = configs[i];
			var calendar = this.createCalendar(settings);
			calendar.id = i;
			this.addCalendar(calendar);
		}
	},

	defaults: {
		global: {
			id: "global",
			worktime: {
				hours: [8, 12, 13, 17],
				days: [0, 1, 1, 1, 1, 1, 0]
			}
		},
		fulltime: {
			id: "fulltime",
			worktime: {
				hours: [0, 24],
				days: [1, 1, 1, 1, 1, 1, 1]
			}
		}
	},

	createDefaultCalendars: function () {
		var config = this.$gantt.config;
		this.restoreConfigCalendars(this.defaults);
		this.restoreConfigCalendars(config.worktimes);
	},

	isCalendar: function(possibleCalendar) {
		// because we don't have any way to check without dependency to CalendarWorkTimeStrategy
		var props = [
			possibleCalendar.isWorkTime,
			possibleCalendar.setWorkTime,
			possibleCalendar.getWorkHours,
			possibleCalendar.unsetWorkTime,
			possibleCalendar.getClosestWorkTime,
			possibleCalendar.calculateDuration,
			possibleCalendar.hasDuration,
			possibleCalendar.calculateEndDate
		];
		return props.every(function(entry) {
			return entry instanceof Function;
		});
	}
};

module.exports = CalendarManager;