module.exports = function (gantt) {

	var BaseEditor = require("./base")(gantt),
		utils = require("../../../../../utils/utils");
	var __extends = require("../../../../../utils/extends");

	function NumberEditor() {
		var self = BaseEditor.apply(this, arguments) || this;
		return self;
	}

	__extends(NumberEditor, BaseEditor);

	utils.mixin(NumberEditor.prototype, {
		show: function (id, column, config, placeholder) {
			var min = config.min || 0,
				max = config.max || 100;

			var html = "<div><input type='number' min='" + min + "' max='" + max + "' name='" + column.name + "'></div>";
			placeholder.innerHTML = html;
		},
		get_value: function (id, column, node) {
			return this.get_input(node).value || "";
		},
		is_valid: function (value, id, column, node) {
			return !isNaN(parseInt(value, 10));
		}
	}, true);

	return NumberEditor;
};