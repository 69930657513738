module.exports = function createResourceTimelineBuilder(gantt){

	var resourceTaskCache = {};

	gantt.$data.tasksStore.attachEvent("onStoreUpdated", function(){
		resourceTaskCache = {};
	});

	function getResourceLoad(resource, resourceProperty, scale, timeline){
		var cacheKey = resource.id + "_" + resourceProperty  + "_" + scale.unit + "_" + scale.step;
		var res;
		if (!resourceTaskCache[cacheKey]) {
			res = resourceTaskCache[cacheKey] = calculateResourceLoad(resource, resourceProperty, scale, timeline);

		} else {
			res = resourceTaskCache[cacheKey];
		}
		return res;
	}

	function calculateResourceLoad(resource, resourceProperty, scale, timeline) {

		var tasks;
		if(resource.$role == "task"){
			tasks = [];
		}else{
			tasks = gantt.getTaskBy(resourceProperty, resource.id);
		}
		var scaleUnit = scale.unit;
		var scaleStep = scale.step;
		var timegrid = {};

		for (var i = 0; i < tasks.length; i++) {
			var task = tasks[i];

			var currDate = gantt.date[scaleUnit + "_start"](new Date(task.start_date));

			while (currDate < task.end_date) {

				var date = currDate;
				currDate = gantt.date.add(currDate, scaleStep, scaleUnit);

				if (!gantt.isWorkTime({date: date, task: task, unit: scaleUnit})) {
					continue;
				}

				var timestamp = date.valueOf();
				if (!timegrid[timestamp]){
					timegrid[timestamp] = [];
				}

				timegrid[timestamp].push(task);
			}
		}

		var timetable = [];
		var start, end, tasks;
		var config = timeline.$getConfig();

		for(var i = 0; i < scale.trace_x.length; i++){
			start = new Date(scale.trace_x[i]);
			end = gantt.date.add(start, scaleStep, scaleUnit);
			tasks = timegrid[start.valueOf()] || [];
			if(tasks.length || config.resource_render_empty_cells){
				timetable.push({
					start_date: start,
					end_date: end,
					tasks: tasks
				});
			}else{
				timetable.push(null);
			}
		}

		return timetable;
	}



	return getResourceLoad;
};