var createStaticBgHelper = function(){
	return {
		render: function () { },
		destroy: function () { }
	};
};

module.exports = {
	create: function(){
		return createStaticBgHelper();
	}
};

