var createBaseBarRender = require("./task_bar_render");
var isInViewPort = require("./viewport/is_split_task_in_viewport");
var getVisibleRange = require("./viewport/get_visible_bars_range");

function createTaskRenderer(gantt){
	var defaultRender = createBaseBarRender(gantt);

	function renderSplitTask(task, timeline) {
		if (gantt.isSplitTask(task) && ((gantt.config.open_split_tasks && !task.$open) || !gantt.config.open_split_tasks)) {
			var el = document.createElement('div'),
				sizes = gantt.getTaskPosition(task);

			if(gantt.hasChild(task.id)){
				gantt.eachTask(function(child){
					var isProject = gantt.isSummaryTask(child);
					if(isProject){
						gantt.resetProjectDates(child);
					}
					var element = defaultRender(child, timeline);
					if(!element)
						return;

					var padding = Math.floor((gantt.config.row_height - sizes.height) / 2);

					element.style.top = (sizes.top + padding) + "px";
					element.classList.add("gantt_split_child");
					if(isProject){
						element.classList.add("gantt_split_subproject");
					}

					el.appendChild(element);
				}, task.id);
			}
			return el;
		}
		return false;
	}
	return {
		render: renderSplitTask,
		update: null,
		//getRectangle: getBarRectangle
		isInViewPort: isInViewPort,
		getVisibleRange: getVisibleRange
	};
}

module.exports = createTaskRenderer;