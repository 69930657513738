var createLayerFactory = require("./render/layer_engine");
function initLayer(layer, gantt){
	if(!layer.view){
		return;
	}

	var view = layer.view;
	if(typeof view === "string"){
		view = gantt.$ui.getView(view);
	}

	if(view && view.attachEvent){
		view.attachEvent("onScroll", function(){
			if(layer.requestUpdate){
				layer.requestUpdate();
			}
		});
	}
}

var createLayerEngine = function(gantt){
	var factory = createLayerFactory(gantt);
	return {
		getDataRender: function(name){
			return gantt.$services.getService("layer:" + name) || null;
		},
		createDataRender: function(config){
			var name = config.name,
				defaultContainer = config.defaultContainer,
				previusSiblingContainer = config.defaultContainerSibling;

			var layers = factory.createGroup(
				defaultContainer,
				previusSiblingContainer,
				function(itemId, item){
					if(layers.filters){
						for(var i = 0; i < layers.filters.length; i++){
							if(layers.filters[i](itemId, item) === false){
								return false;
							}
						}
					}else{
						return true;
					}
				},
				initLayer
			);

			gantt.$services.setService("layer:" + name, function(){
				return layers;
			});

			gantt.attachEvent("onGanttReady", function () {
				layers.addLayer();// init layers on start
			});

			return layers;
		},
		init: function(){
			var taskLayers = this.createDataRender({
				name: "task",
				defaultContainer: function(){
					if(gantt.$task_data){
						return gantt.$task_data;
					}else if(gantt.$ui.getView("timeline")){
						return gantt.$ui.getView("timeline").$task_data;
					}
				},
				defaultContainerSibling: function(){
					if(gantt.$task_links){
						return gantt.$task_links;
					}else if(gantt.$ui.getView("timeline")){
						return gantt.$ui.getView("timeline").$task_links;
					}
				},
				filter: function(item){

				}
			}, gantt);

			var linkLayers = this.createDataRender({
				name: "link",
				defaultContainer: function(){
					if(gantt.$task_data){
						return gantt.$task_data;
					}else if(gantt.$ui.getView("timeline")){
						return gantt.$ui.getView("timeline").$task_data;
					}
				}
			}, gantt);

			return {
				addTaskLayer: function(config){
					if(typeof config === "function"){
						config = {
							renderer: config
						};
					}
					config.view = "timeline";

					return taskLayers.addLayer(config);
				},

				_getTaskLayers: function(){
					return taskLayers.getLayers();
				},
				removeTaskLayer: function(id){
					taskLayers.removeLayer(id);
				},

				_clearTaskLayers: function(){
					taskLayers.clear();
				},
				addLinkLayer: function(config){
					if(typeof config === "function"){
						config = {
							renderer: config
						};
					}
					config.view = "timeline";
					return linkLayers.addLayer(config);
				},

				_getLinkLayers: function(){
					return linkLayers.getLayers();
				},
				removeLinkLayer: function(id){
					linkLayers.removeLayer(id);
				},

				_clearLinkLayers: function(){
					linkLayers.clear();
				}
			};
		}
	};
};

module.exports = createLayerEngine;