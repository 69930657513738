module.exports = function(gantt){
	if(!gantt.ext){
		gantt.ext = {};
	}

	var modules = [
		require("./batch_update"),
		require("./wbs"),
		require("./resources"),
		require("./new_task_placeholder"),
		require("./auto_task_types"),
		require("./formatters")
	];

	for(var i = 0; i < modules.length; i++){
		if(modules[i])
			modules[i](gantt);
	}
};