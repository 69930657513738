

import { IWorkUnitCache } from "./workunit_cache_interface";

export class WorkUnitsMapCache implements IWorkUnitCache {
	private _cache: Map<string, Map<string, boolean>>;
	constructor() {
		this.clear();
	}

	getItem(unit: string, timestamp: string): number|boolean {
		if (this._cache.has(unit)) {
			const unitCache = this._cache.get(unit);
			if (unitCache.has(timestamp)) {
				return unitCache.get(timestamp);
			}
		}

		return -1;
	}
	setItem(unit: string, timestamp: string, value: boolean): void {
		if (!unit || !timestamp) {
			return;
		}

		const cache = this._cache;

		let unitCache;
		if (!cache.has(unit)) {
			unitCache = new Map<string, boolean>();
			cache.set(unit, unitCache);
		} else {
			unitCache = cache.get(unit);
		}
		unitCache.set(timestamp, value);
	}
	clear(): void{
		this._cache = new Map<string, Map<string, boolean>>();
	}
}